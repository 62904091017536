
import { defineComponent, ref } from "vue";
import base from "@/api/base.js";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

interface Filter {
  status: string;
  code: string;
}

export default defineComponent({
  name: "checkitems",

  components: { VPagination },

  data() {
    return {
      checkItems: [],
      searchcode: "cmp_nm",
      searchdata: "",
      status: "",
      page: 1,
    };
  },

  created() {
    this.getListData();
  },

  methods: {
    getSearchResult() {
      this.page = 1;
      this.getListData();
    },

    getListData() {
      const param = {
        [this.searchcode]: this.searchdata,
        check_item_use_yn: this.status,
        page: this.page,
      };
      base
        .getCheckItemList(param)
        .then((res) => {
          console.log(res);
          this.checkItems = res.data;
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          const value = Object.values(err.response.data);
          alert(value);
        });
    },

    fnView(id) {
      console.log(id);
      sessionStorage.setItem("id", id);
      this.$router.push({
        name: "check-items-detail",
        params: { id: id },
      });
    },
  },
  setup() {
    const data = ref<Filter>({
      status: "1",
      code: "1",
    });

    return {
      data,
    };
  },
});
